import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HeaderCard from "../../Common/Component/HeaderCard";
import DataTableComponent from "./Players/PlayersTableComponent";
import CoachTableComponent from "./Coach/CoachTableComponent";
import ImagesTableComponent from "./Images/ImagesTableComponent";
import VideosTableComponent from "./Videos/VideosTableComponent";
import EventsTableComponent from "./Events/EventsTableComponent";

const DataTables = (props) => {
  const { keyProp } = props;

  return (
    <Fragment>
      <Container fluid={true}>
        <Row style={{ paddingTop: "3%" }}>
          <Col sm="12">
            <Card>
              <HeaderCard title={keyProp} />
              <CardBody>
                {keyProp === "players" ? (
                  <DataTableComponent />
                ) : keyProp === "coach" ? (
                  <CoachTableComponent />
                ) : keyProp === "images" ? (
                  <ImagesTableComponent />
                ) : keyProp === "videos" ? (
                  <VideosTableComponent />
                ) : keyProp === "events" ? (
                  <EventsTableComponent />
                ) : (
                  <p>Invalid keyProp</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DataTables;
