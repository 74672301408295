import React, { Fragment } from "react";
import { Btn, H4, H5, Image, P } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Button,
  Spinner,
} from "reactstrap";
import {
  EditProfile,
  Company,
  Username,
  UsersCountryMenu,
  AboutMe,
  UpdateProfile,
  FirstName,
  LastName,
  Address,
  EmailAddress,
  PostalCode,
  Country,
  City,
} from "../../../../Constant";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import ProfileService from "../../../../Services/ProfileServices";
import { toast } from "react-toastify";
import { CUSTOMER_ENDPOINT_URL } from "../../../../Config/AppConfig";
import Spinners from "../../../UiKits/Spinners";

const EditMyProfile = () => {
  const [url, setUrl] = useState("");

  const [formData, setFormData] = useState({
    firstName: "test2",
    lastName: "admin2",
    email: "123",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    profile_picture: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    console.log("formData = ", formData);
    e.preventDefault();

    let newErrors = { ...errors };
    if (formData.firstName.trim() === "") {
      newErrors.firstName = "First Name is required";
    }
    if (formData.lastName.trim() === "") {
      newErrors.lastName = "Last Name is required";
    }

    setErrors(newErrors);

    if (validateForm(newErrors)) {
      console.log("Valid form submitted");
      console.log("formData = ", formData);
      ProfileService.UpdateAdminDetails(formData)
        .then((resp) => {
          localStorage.setItem(
            "Name",
            formData.firstName + " " + formData.lastName
          );
          localStorage.setItem(
            "profileURL",
            `${CUSTOMER_ENDPOINT_URL}/public/admin/${resp.data.profile_picture}`
          );
          toast.success("Details updated successfully!..");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          console.log("error  = ", err.data);
        });
    } else {
      console.log("Invalid form submission");
    }
  };

  const validateForm = (errors) => {
    return Object.values(errors).every((error) => error === "");
  };

  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      toast.error("Only image files are allowed..!");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result);
      setFormData({
        ...formData,
        profile_picture: reader.result,
      });
    };
  };

  useEffect(() => {
    // console.log("profileURL == ",localStorage.getItem("profileURL"))
    setUrl(localStorage.getItem("profileURL"));
    ProfileService.GetAdminDetails()
      .then((resp) => {
        console.log("resp.data.profile_picture = ", resp.data.profile_picture);
        setFormData({
          ...formData,
          firstName: resp.data.first_name,
          lastName: resp.data.last_name,
          email: resp.data.email,
          city: resp.data.city,
          state: resp.data.state,
          country: resp.data.country,
          zip_code: resp.data.zip_code,
        });
        setUrl(
          `${CUSTOMER_ENDPOINT_URL}/public/admin/${resp.data.profile_picture}`
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log("error  = ", err.data);
      });
  }, []);
  const [loading, setLoading] = useState(true);
  return (
    <Row style={{ paddingTop: "3%" }}>
      {loading ? (
        <p style={{ textAlign: "center", lineHeight: "60vh" }}>Loading...</p>
      ) : (
        <>
          <Col xl="4">
            <Fragment>
              <Card>
                <CardHeader>
                  <H4 attrH4={{ className: "card-title mb-0" }}>
                    {"My Profile"}
                  </H4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <div className="user-profile">
                        <Card className="hovercard text-center">
                          <div className="media-body">
                            <H5 attrH5={{ className: "mb-1" }}>
                              {`${formData.firstName} ${formData.lastName}`.toUpperCase()}
                            </H5>
                            <P>ADMIN</P>
                          </div>
                          {/* <div className="user-designation">
                        <div className="title">
                          <a>
                            {formData.firstName} {formData.lastName}
                          </a>
                        </div>
                        <div className="desc mt-2">{"Admin"}</div>
                      </div> */}
                          <div
                            className="user-image"
                            style={{ marginTop: "80px" }}
                          >
                            <div className="avatar">
                              <Image
                                attrImage={{
                                  className: "step1",
                                  alt: "",
                                  src: `${
                                    url
                                      ? url
                                      : require("../../../../assets/images/user/5.jpg")
                                  }`,
                                }}
                              />
                            </div>
                            <div
                              className="icon-wrapper step2"
                              data-intro="Change Profile image here"
                            >
                              <i
                                className="icofont icofont-pencil-alt-5"
                                onChange={(e) => readUrl(e)}
                              >
                                <input
                                  className="upload"
                                  type="file"
                                  accept="image/*"
                                  // onChange={(e) => readUrl(e)}
                                />
                              </i>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Row>
                    <FormGroup className="mb-3">
                      <Label className="form-label">{"Email address"}</Label>
                      <Input
                        className="form-control"
                        value={formData.email}
                        disabled
                      />
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Fragment>
          </Col>
          <Col xl="8">
            <Fragment>
              <Form className="card" onSubmit={handleSubmit}>
                <CardHeader>
                  <H4 attrH4={{ className: "card-title mb-0" }}>
                    {"EditProfile"}
                  </H4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">
                          First Name<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          onChange={handleChange}
                          value={formData.firstName}
                        />
                        {errors.firstName.length > 0 && (
                          <span style={{ color: "red" }}>
                            {errors.firstName}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label for="lastName">
                          Last Name<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="lastName"
                          name="lastName"
                          id="lastName"
                          onChange={handleChange}
                          value={formData.lastName}
                        />
                        {errors.lastName.length > 0 && (
                          <span style={{ color: "red" }}>
                            {errors.lastName}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label for="city">City</Label>
                        <Input
                          type="city"
                          name="city"
                          id="city"
                          onChange={handleChange}
                          value={formData.city}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label for="state">State</Label>
                        <Input
                          type="state"
                          name="state"
                          id="state"
                          onChange={handleChange}
                          value={formData.state}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label for="zip_code">Zip Code</Label>
                        <Input
                          type="zip_code"
                          name="zip_code"
                          id="zip_code"
                          onChange={handleChange}
                          value={formData.zip_code}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label for="country">Country</Label>
                        <Input
                          type="country"
                          name="country"
                          id="country"
                          onChange={handleChange}
                          value={formData.country}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-end">
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </CardFooter>
              </Form>
            </Fragment>
          </Col>
        </>
      )}
    </Row>
  );
};
export default EditMyProfile;
