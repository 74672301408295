import React from "react";
import Routers from "./Route";
import TableProvider from "./_helper/Table/TableProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";

const App = () => (
  <div className="App">
    <CustomizerProvider>
      <TableProvider>
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>
      </TableProvider>
    </CustomizerProvider>
  </div>
);

export default App;
