import React, { Fragment, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { EmailAddress, Password, RememberPassword, SignIn } from "../Constant";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import JwtAuthService from "../Services/JwtAuthService";
import { CUSTOMER_ENDPOINT_URL } from "../../src/Config/AppConfig";

const Signin = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const history = useNavigate();

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    if (emailValue) {
      setEmailError('');
    }else{
      setEmailError('Email is required');
    }
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    if (passwordValue) {
      setPasswordError('');
    }else{
      setPasswordError('Password is required');
    }
  };

  const loginAuth = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError('Email is required');
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Password is required');
    } else {
      setPasswordError('');
    }
    if (email && password) {
      let data = {
        email: email,
        password: password,
      };
      JwtAuthService.login(data)
        .then((resp) => {
          localStorage.setItem("login", JSON.stringify(true));
          localStorage.setItem("Name", resp.name);
          localStorage.setItem("access_token", resp.access_token);
          localStorage.setItem("authenticated", true);
          localStorage.setItem("profileURL", `${CUSTOMER_ENDPOINT_URL}/public/admin/${resp.profile_picture}`);
          history(`/players`);
          toast.success("Successfully logged in!..");
        })
        .catch((error) => {
          console.log(error);
          toast.error("You enter wrong password or username!..");
        });
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>
                    {selected === "simpleLogin"
                      ? "Sign In With Simple Login"
                      : "Admin Sign In"}
                  </H4>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      className="form-control"
                      type="email"
                      // onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <span className="error-message">{emailError}</span>
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        // onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                    <span className="error-message">{passwordError}</span>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "d-block w-100 mt-2",
                        onClick: (e) => loginAuth(e),
                      }}
                    >
                      {SignIn}
                    </Btn>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
