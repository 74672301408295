import fetch from "../interceptor/fetchInterceptor";

const PlayerService = {};
PlayerService.GetAllPlayers = function () {
  return fetch({
    url: "/admin/players",
    method: "get",
    headers: {
      "public-request": "true",
    }
  });
};
PlayerService.GetPlayerDetails = function (params) {
  return fetch({
    url: "/admin/player",
    method: "get",
    headers: {
      "public-request": "true",
    },
    params,
  });
};

export default PlayerService;
