const dev = {
  // API_ENDPOINT_URL: "https://yaf.kretosstechnology.com/api/v1",
  // BASE_URL: "http://localhost:3000",
  // CUSTOMER_ENDPOINT_URL: "https://yaf.kretosstechnology.com",


  API_ENDPOINT_URL: "https://yaf.kretosstechnology.com/api/v1",
  BASE_URL: "https://yafadmin.kretosstechnology.com/",
  CUSTOMER_ENDPOINT_URL: "https://yaf.kretosstechnology.com",
};
const prod = {
  // API_ENDPOINT_URL: "https://yaf.kretosstechnology.com/api/v1",
  // BASE_URL: "http://localhost:3000",
  // CUSTOMER_ENDPOINT_URL: "https://yaf.kretosstechnology.com",
  
  API_ENDPOINT_URL: "https://yaf.kretosstechnology.com/api/v1",
  BASE_URL: "https://yafadmin.kretosstechnology.com/",
  CUSTOMER_ENDPOINT_URL: "https://yaf.kretosstechnology.com",
};

const test = {
  // API_ENDPOINT_URL: "https://yaf.kretosstechnology.com/api/v1",
  // BASE_URL: "http://localhost:3000",
  // CUSTOMER_ENDPOINT_URL: "https://yaf.kretosstechnology.com",
  
  API_ENDPOINT_URL: "https://yaf.kretosstechnology.com/api/v1",
  BASE_URL: "https://yafadmin.kretosstechnology.com/",
  CUSTOMER_ENDPOINT_URL: "https://yaf.kretosstechnology.com",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
