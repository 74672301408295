import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router";
import PlayerService from "../../../../../Services/PlayerService";
import { Card, Col, Row } from "reactstrap";
import UpcomingSchedule from "../../../../Dashboard/OnlineCourse/UpcomingSchedule";
import { H6, Image } from "../../../../../AbstractElements";
import { CUSTOMER_ENDPOINT_URL } from "../../../../../Config/AppConfig";
import MediaService from "../../../../../Services/MediaService";

const ImageDetail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState("");
  const id = searchParams.get("id");
 
  useEffect(() => {
    MediaService.GetImageDetails({ image_id: id })
    .then((response)=>{
      console.log("Response image details = ",response)
    })
    .catch((error)=>{
      console.log("Error = ",error)
    })
  }, []);

  return (
    <div>
      <div className="user-profile" style={{paddingTop: '3%'}}>
        {/* <Fragment>
          <Col sm="12">
            <Card className="hovercard text-center">
              <div className="user-image user-profile-image">
                <div className="avatar">
                  <Image
                    attrImage={{
                      className: "step1",
                      alt: "",
                      src: `${CUSTOMER_ENDPOINT_URL}/public/profile/${data.profile_picture == null ? "default/default_user.png" : data.profile_picture}`,
                    }}
                  />
                </div>
              </div>
              <div className="info">
                <Row className="step3" data-intro="This is the your details">
                  <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                    <Row>
                      <Col md="6">
                        <div className="ttl-info text-start">
                          <H6>
                            <i className="fa fa-envelope me-2"></i> {"Email"}
                          </H6>
                          <span>{data.email}</span>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <H6>
                            <i className="fa fa-calendar me-2"></i>
                            {"Joining Date"}
                          </H6>
                          <span>
                            {new Date(data.createdAt).toLocaleDateString(
                              "en-GB"
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                    <div className="user-designation">
                      <div className="title">
                        <a>
                          {data.first_name} {data.last_name}
                        </a>
                      </div>
                      <div className="desc mt-2">{data.role}</div>
                    </div>
                  </Col>
                  <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                    <Row>
                      <Col md="6">
                        <div className="ttl-info text-start ttl-xs-mt">
                          <H6>
                            <i className="fa fa-phone me-2"></i>
                            {"Phone Number"}
                          </H6>
                          <span>{data.phone ? data.phone : "1234567890"}</span>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <H6>
                            <i className="fa fa-location-arrow me-2"></i>
                            {"Country"}
                          </H6>
                          <span>{data.country ? data.country : "India"}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />
              </div>
            </Card>
          </Col>
        </Fragment> */}
        Image Details {id}
      </div>
    </div>
  );
};

export default ImageDetail;
