import fetch from "../interceptor/fetchInterceptor";

const JwtAuthService = {};
JwtAuthService.login = function (data) {
  return fetch({
    url: "/admin/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

export default JwtAuthService;
