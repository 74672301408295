import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { H5, Btn } from "../../../AbstractElements";
import ScheduleListBox from "./ScheduleListBox";



const UpcomingSchedule = (data) => {
  return (
    <Card className="schedule-card">
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5 attrH5={{ className: "m-0" }}>{data.data}</H5>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <ScheduleListBox data={data.value} color={data.color}/>
      </CardBody>
    </Card>
  );
};

export default UpcomingSchedule;
