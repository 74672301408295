import { env } from './EnvironmentConfig'

export const APP_NAME = 'Emilus';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const CUSTOMER_ENDPOINT_URL = env.CUSTOMER_ENDPOINT_URL
// export const APP_PREFIX_PATH = '/app';
// export const AUTH_PREFIX_PATH = '/auth';

export const BASE_URL = env.BASE_URL;
export const SUBFOLDER = '/';// for live

//  export const BASE_URL = 'http://localhost:3006'
//  export const SUBFOLDER = '/' // for local