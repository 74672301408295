import PlayerDetail from "../Components/Tables/DataTable/Players/details/PlayerDetail";
import CoachDetail from "../Components/Tables/DataTable/Coach/details/CoachDetail";
import EditMyProfile from "../Components/Application/Users/UsersEdit/EditmyProfile";
import DataTables from "../Components/Tables/DataTable";
import ImageDetail from "../Components/Tables/DataTable/Images/details/ImageDetail";
import VideoDetail from "../Components/Tables/DataTable/Videos/details/VideoDetail";
import EventDetail from "../Components/Tables/DataTable/Events/details/EventDetail";
console.log("process.env.PUBLIC_URL = ",process.env.PUBLIC_URL)
export const routes = [
  { path: `/players`, Component: <DataTables keyProp="players" /> },
  { path: `/player`, Component: <PlayerDetail /> },
  { path: `/coach`, Component: <DataTables keyProp="coach" /> },
  { path: `/images`, Component: <DataTables keyProp="images" /> },
  { path: `/image`, Component: <ImageDetail /> },
  { path: `/videos`, Component: <DataTables keyProp="videos" /> },
  { path: `/video`, Component: <VideoDetail /> },
  { path: `/events`, Component: <DataTables keyProp="events" /> },
  { path: `/event`, Component: <EventDetail /> },
  { path: `/coach/details`, Component: <CoachDetail /> },
  { path: `/profile`, Component: <EditMyProfile /> },
];
