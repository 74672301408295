import fetch from "../interceptor/fetchInterceptor";

const MediaService = {};
MediaService.GetAllImages = function () {
  return fetch({
    url: "/admin/images",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

MediaService.GetAllVideos = function () {
  return fetch({
    url: "/admin/videos",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

MediaService.GetAllEvents = function () {
  return fetch({
    url: "/admin/events",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

// MediaService.GetImageDetails = function (params) {
//   return fetch({
//     url: "/admin/image",
//     method: "get",
//     headers: {
//       "public-request": "true",
//     },
//     params,
//   });
// };

export default MediaService;
