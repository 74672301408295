import fetch from "../interceptor/fetchInterceptor";

const CoachService = {};
CoachService.GetAllCoach = function () {
  return fetch({
    url: "/admin/coach",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};
CoachService.GetCoachDetails = function (params) {
  return fetch({
    url: "/admin/coach/details",
    method: "get",
    headers: {
      "public-request": "true",
    },
    params,
  });
};

export default CoachService;
