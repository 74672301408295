import React from "react";
import { H6, Image, UL, LI } from "../../../AbstractElements";

const ScheduleListBox = ({ data, color }) => {
  return (
    <UL attrUL={{ className: "schedule-list d-flex" }}>
      {data &&
        Object.keys(data).map((key, i) => (
          <LI key={i} attrLI={{ className: `${color}` }}>
            <div>
              <H6 className="mb-1">{key}</H6>
              <UL>
                <LI attrLI={{ className: "f-light" }}>
                  <span>{data[key]}</span>
                </LI>
              </UL>
            </div>
          </LI>
        ))}
    </UL>
  );
};

export default ScheduleListBox;
