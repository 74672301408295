import React, { Fragment, useCallback,  useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Btn, H4 } from "../../../../AbstractElements";
import { useNavigate } from "react-router-dom";
import CoachService from "../../../../Services/CoachService";
import { Tooltip } from "reactstrap";

const CoachTableComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const [basictooltip, setbasictooltip] = useState(false);
  const toggle = () => setbasictooltip(!basictooltip);

  useEffect(() => {
    CoachService.GetAllCoach()
      .then((resp) => {
        if(resp){
          console.log("response = ", resp.data);
          setData(resp.data);
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error);
        navigate(`/login`);
      });
  }, []);

  // const tempData = [
  //   {
  //     id: 1,
  //     first_name: "Danial",
  //     last_name: "Sams",
  //     email: "danial@gmail.com",
  //     phone: "1234567890",
  //     date: "2023/04/27",
  //   },
  //   {
  //     id: 2,
  //     first_name: "John",
  //     last_name: "Doe",
  //     email: "john.doe@example.com",
  //     phone: "9876543210",
  //     date: "2023/04/28",
  //   },
  //   {
  //     id: 3,
  //     first_name: "Alice",
  //     last_name: "Smith",
  //     email: "alice.smith@example.com",
  //     phone: "5555555555",
  //     date: "2023/04/29",
  //   },
  //   {
  //     id: 4,
  //     first_name: "Bob",
  //     last_name: "Johnson",
  //     email: "bob.johnson@example.com",
  //     phone: "7777777777",
  //     date: "2023/04/30",
  //   },
  //   {
  //     id: 5,
  //     first_name: "Eva",
  //     last_name: "Green",
  //     email: "eva.green@example.com",
  //     phone: "8888888888",
  //     date: "2023/05/01",
  //   },
  //   {
  //     id: 6,
  //     first_name: "Michael",
  //     last_name: "Williams",
  //     email: "michael.williams@example.com",
  //     phone: "9999999999",
  //     date: "2023/05/02",
  //   },
  //   {
  //     id: 7,
  //     first_name: "Sara",
  //     last_name: "Johnson",
  //     email: "sara.johnson@example.com",
  //     phone: "7777777777",
  //     date: "2023/05/03",
  //   },
  //   {
  //     id: 8,
  //     first_name: "David",
  //     last_name: "Brown",
  //     email: "david.brown@example.com",
  //     phone: "3333333333",
  //     date: "2023/05/04",
  //   },
  //   {
  //     id: 9,
  //     first_name: "Emily",
  //     last_name: "Wilson",
  //     email: "emily.wilson@example.com",
  //     phone: "2222222222",
  //     date: "2023/05/05",
  //   },
  //   {
  //     id: 10,
  //     first_name: "James",
  //     last_name: "Davis",
  //     email: "james.davis@example.com",
  //     phone: "1111111111",
  //     date: "2023/05/06",
  //   },
  // ];

  const temptableColumns = [
    {
      name: "Name",
      selector: (row) => row["first_name"] + " " + row["last_name"],
      sortable: true,
      center: false,
    },
    {
      name: "Email",
      selector: (row) => `${row.email}`,
      sortable: true,
      center: false,
    },
    {
      name: "Phone Number",
      selector: (row) => `${row.phone ? row.phone : "1234567890"}`,
      sortable: true,
      center: false,
    },
    {
      name: "Joining Date",
      selector: (row) => {
        const originalDate = new Date(row.createdAt).toLocaleDateString('en-GB');
        return originalDate;
      },
      sortable: true,
      center: true,
    },
    {
      name: "",
      center: true,
      selector: (row) => (
        <a
          id='TooltipExample'
          href={`/coach/details?id=${row.id}`}
          className="mr-2"
          style={{
            padding: '5px',
            backgroundColor: 'blue',
            color: 'white', 
          }}
        >
         
          <span  role="img" aria-label="eye" className="anticon anticon-eye" style={{ paddingTop: "3px", display: "inline-block"}}>
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="eye"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
            </svg>
            <Tooltip
              placement= 'top'
              isOpen= {basictooltip}
              target= 'TooltipExample'
              toggle= {toggle}
              style={{marginBottom: '5px'}}
             >
                View
            </Tooltip>
          </span>
        </a>
      ),
    }
  ];

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    if (
      window.confirm(
        `Are you sure you want to delete:\r ${selectedRows.map(
          (r) => r.title
        )}?`
      )
    ) {
      setToggleDelet(!toggleDelet);

      setData(
        data.filter((item) =>
          selectedRows.filter((elem) => elem.id === item.id).length > 0
            ? false
            : true
        )
      );
      setSelectedRows("");
    }
  };
  const [loading, setLoading] = useState(true);
  return (
    <Fragment>
      {loading && (<p style={{ textAlign: 'center', lineHeight: '60vh' }}>Loading...</p>)}
      {/* {selectedRows.length !== 0 && (
        <div
          className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
        >
          <H4 attrH4={{ className: "text-muted m-0" }}>
            Delet Selected Data..!
          </H4>
          <Btn attrBtn={{ color: "danger", onClick: () => handleDelete() }}>
            Delete
          </Btn>
        </div>
      )} */}
      
      <DataTable
        data={data}
        columns={temptableColumns}
        striped={true}
        center={true}
        pagination
        // selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelet}
      />
    </Fragment>
  );
};
export default CoachTableComponent;
