import fetch from "../interceptor/fetchInterceptor";

const ProfileService = {};

ProfileService.GetAdminDetails = function () {
  return fetch({
    url: "/admin/details",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

ProfileService.UpdateAdminDetails = function (data) {
  return fetch({
    url: "/update/admin/details",
    method: "put",
    headers: {
      "public-request": "true",
    },
    data: data
  });
};

export default ProfileService;
