import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import MediaService from "../../../../Services/MediaService";

const VideosTableComponent = () => {
  const [data, setData] = useState();
  const [basictooltip, setbasictooltip] = useState(false);
  const toggle = () => setbasictooltip(!basictooltip);

  useEffect(()=>{
    MediaService.GetAllVideos()
      .then((resp)=>{
        console.log("Response = ",resp)
        setData(resp.data);
        setLoading(false)
      })
      .catch((error)=>{
        console.log(error);
      })
  },[])


  const temptableColumns = [
    {
      name: "Video",
      selector: (row) => row["thumbnail"],
      sortable: true,
      center: false,
      cell: (row) => <img src={row.thumbnail} alt={row.userName} style={{ width: '50px', height: '50px' }} />,
    },
    {
      name: "User",
      selector: (row) => row["userName"],
      sortable: true,
      center: false,
    },
    {
      name: "Date",
      selector: (row) => {
        const originalDate = new Date(row.date).toLocaleDateString('en-GB');
        return originalDate;
      },
      sortable: true,
      center: true,
    },

    // {
    //   name: "",
    //   center: true,
    //   selector: (row) => (
    //     <a
    //       id='TooltipExample'
    //       href={`/video?id=${row.id}`}
    //       className="mr-2"
    //       style={{
    //         padding: '5px',
    //         backgroundColor: 'blue',
    //         color: 'white'
    //       }}
    //     >
    //       <span role="img" aria-label="eye" className="anticon anticon-eye" style={{ paddingTop: "3px", display: "inline-block"}}>
    //         <svg
    //           viewBox="64 64 896 896"
    //           focusable="false"
    //           data-icon="eye"
    //           width="1em"
    //           height="1em"
    //           fill="currentColor"
    //           aria-hidden="true"
    //         >
    //           <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
    //         </svg>
    //         <Tooltip
    //           placement= 'top'
    //           isOpen= {basictooltip}
    //           target= 'TooltipExample'
    //           toggle= {toggle}
    //           style={{marginBottom: '5px'}}
    //          >
    //             View
    //         </Tooltip>
    //       </span>
    //     </a>
    //   ),
    // }
  ];
  const [loading, setLoading] = useState(true);
  
  return (
    <Fragment>
      {loading && (<p style={{ textAlign: 'center', lineHeight: '60vh' }}>Loading...</p>)}
      <DataTable
        data={data}
        columns={temptableColumns}
        striped={true}
        center={true}
        pagination
        style={{display: 'none'}}
      />
    </Fragment>
  );
};
export default VideosTableComponent;
