export const MENUITEMS = [
  {
      Items: [
        {
          title: "Players",
          icon: "table",
          type: "link",
          path: `/players`,
        },
        {
          title: "Coach",
          icon: "table",
          type: "link",
          path: `/coach`,
        },
        {
          title: "Images",
          icon: "table",
          type: "link",
          path: `/images`,
        },
        {
          title: "Videos",
          icon: "table",
          type: "link",
          path: `/videos`,
        },
        {
          title: "Events",
          icon: "table",
          type: "link",
          path: `/events`,
        },
      ],
    }
];
